import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

enum keys {
  FIRST_STARTUP = 'FirstStartupDone',
  MY_SCRIPTS_STARTUP_MODAL = 'MyScriptsStartupModalViewed',
};

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  async set(key: string, value: string) {
    await Preferences.set({ key, value });
  }

  async get(key: string) {
    return (await Preferences.get({ key })).value;
  }

  async checkFirstStartup() {
    const value = await this.get(keys.FIRST_STARTUP);
    if (value === null) {
      this.set(keys.FIRST_STARTUP, 'done');
      return true;
    } else {
      return false;
    }
  }

  async checkMyScriptsStartupModal() {
    const value = await this.get(keys.MY_SCRIPTS_STARTUP_MODAL);
    if (value === null) {
      this.set(keys.MY_SCRIPTS_STARTUP_MODAL, 'done');
      return true;
    } else {
      return false;
    }
    
  }
}