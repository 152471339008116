import { Component, computed, inject, input, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItemOptions, IonItemOption, IonItem, IonItemSliding, IonList, IonRefresher, IonRefresherContent, RefresherEventDetail, NavController, ModalController, AlertController } from '@ionic/angular/standalone';
import { IonRefresherCustomEvent } from '@ionic/core';
import { TitleComponent } from 'src/app/components/primitives/title/title.component';
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { ErrorService } from 'src/app/services/error.service';
import { CardEway } from '@chemist2u/types-client/C2U/ParseObjects';
import { StateService } from 'src/app/services/state.service';
import { PaymentRowComponent } from 'src/app/components/elements/payment-row/payment-row.component';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { AddCardPage } from '../add-card/add-card.page';
import { LoadingViewComponent } from 'src/app/components/elements/loading-view/loading-view.component';
import { PullService } from 'src/app/services/pull.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { PushService } from 'src/app/services/push.service';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.page.html',
  styleUrls: ['./payments-list.page.scss'],
  standalone: true,
  imports: [LoadingViewComponent, PaymentRowComponent, ButtonComponent, SimpleButtonComponent, TitleComponent, HeaderComponent, IonRefresher, IonRefresherContent, IonItemOptions, IonItemOption, IonItem, IonItemSliding, IonList, IonContent, IonHeader, IonTitle, IonToolbar, CommonModule, FormsModule]
})
export class PaymentsListPage implements OnInit {
  private $pull = inject(PullService);
  private $push = inject(PushService);
  private $state = inject(StateService);
  private $error = inject(ErrorService);
  private navController = inject(NavController);
  private modalController = inject(ModalController);
  private alertController = inject(AlertController);
  private $amplitude = inject(AmplitudeService);

  pageState = input<"modal" | "page">("page");
  canSelect = input<boolean>(false);


  payments = toSignal(this.$state.bCards);

  isLoading = signal<boolean>(false);
  addButtonIsDisabled = signal<boolean>(false);

  paymentRowMode = computed<"select" | "delete" | "none">(() => {
    if (this.canSelect()) {
      return "select";
    } else if (this.pageState() == "page") {
      return "delete";
    } else {
      return "none";
    }
  });

  ngOnInit() {
    this.$amplitude.track('NAVIGATE_CARDS');
  }
  
  async refreshPayments() {
    try {
      await this.$pull.cards();
    } catch (error: any) {
      this.$error.handleParseError(error);
    }
  }

  async doRefresh(event: IonRefresherCustomEvent<RefresherEventDetail>) {
    await this.refreshPayments();
    event.target.complete();
  }

  back() {
    if (this.pageState() === "page") {
      this.navController.navigateBack('/payments-coupons');
      this.$amplitude.track('GENERIC_BACK');
    }

    if (this.pageState() === "modal") {
      this.dismiss();
    }
  }

  async addPaymentMethod() {
    this.addButtonIsDisabled.set(true);
    const modal = await this.modalController.create({
      component: AddCardPage,
      componentProps: {
        required: false,
      },
      canDismiss: true,
      presentingElement: document.querySelector('ion-router-outlet')!,
    });
    await modal.present();
    await modal.onWillDismiss();
    this.addButtonIsDisabled.set(false);
  }

  async destroyCardData(card: CardEway) {
    const alert = await this.alertController.create({
      header: 'Remove Card',
      message: "Would you like to remove card from saved cards ?",
      buttons: [
        { text: 'No', role: 'cancel' },
        { text: 'Yes', role: 'confirm' }
      ]
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();

    if (role !== "confirm") return;
    const affiliatedOrders = this.$state.bOrders.getValue().filter(o => ["Unpaid", "Approved"].includes(o.status || "") && o.card?.objectId == card.id);
    if (affiliatedOrders.length > 0) {
      this.$error.showToast({
        message: "Cannot delete a card while it is still attached to an active order",
        header: "Error",
        position: "top",
        duration: 2000,
      });
      return;
    }
    this.isLoading.set(true);
    await this.$push.destroyCard(card);
    this.$amplitude.track('CARD_REMOVE_SUCCESS');
    this.isLoading.set(false);
  }

  async selectPayment(payment: CardEway) {
    const modalExists = await this.modalController.getTop();
    if (!modalExists) return;
    this.$amplitude.track('CARD_SELECTION_CHANGE');
    this.dismiss(payment);
  }

  dismiss(data?: any) {
    this.modalController.dismiss(data);
  }

}