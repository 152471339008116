import { CurrencyPipe } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { TOrderStatus, TTotalLineItem } from '@chemist2u/types-client/C2U/Interfaces';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { informationCircleOutline } from 'ionicons/icons';
import { PopoverController } from "@ionic/angular/standalone";
import { TooltipService } from 'src/app/services/tooltip.service';

@Component({
  selector: 'app-cart-totals-row',
  templateUrl: './cart-totals-row.component.html',
  styleUrls: ['./cart-totals-row.component.scss'],
  standalone: true,
  imports: [CurrencyPipe, IonIcon]
})
export class CartTotalsRowComponent {
  private tooltipService = inject(TooltipService);
  constructor() {
    addIcons({ informationCircleOutline });
  }
  
  public orderStatus = input<TOrderStatus | undefined>(undefined);
  public isTotal = input<boolean>(false);
  public isSubTotal = input<boolean>(false);

  public lineItem = input.required<TTotalLineItem>();
  public label = computed(() => this.lineItem().label);
  public isReady = computed(() => {
    const statusAllow = this.orderStatus() === undefined ? true : this.orderStatus() != "Unpaid";
    return this.lineItem().isReady && statusAllow;
  });
  public isDiscount = computed(() => this.lineItem().type === 'discount');
  public canDisplay = computed(() => this.lineItem().canDisplay);
  public value = computed(() => this.lineItem().value);
  public message = computed(() => this.lineItem().message);

  async showPopover(event: MouseEvent) {
    if(this.message()) {
      this.tooltipService.show({ label: this.message()!, x: event.clientX, y: event.clientY });
    }
  }
}