import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AmplitudeService } from '../services/amplitude.service';

// Function to extract UTM parameters from the URL
const getUTMParams = (): Record<string, string> => {
  const params = new URLSearchParams(window.location.search);
  const utmParams: Record<string, string> = {};
  ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach(param => {
    const value = params.get(param);
    if (value) {
      utmParams[param] = value;
    }
  });
  return utmParams;
}

export const appDownloadRedirectGuard: CanActivateFn = (route, state) => {
  const $amplitude = inject(AmplitudeService);

  const userAgent = navigator.userAgent || navigator.vendor;
  const forwarded_to =
    /iPad|iPhone|iPod/.test(userAgent) ? "apple_store" :
    /android/i.test(userAgent) ? "android_store" :
    "web";

  const utmData = getUTMParams();
  const utmQueryString = new URLSearchParams(utmData).toString();
  const forward_link = {
    "apple_store": `https://apps.apple.com/au/app/chemist2u/id1501448500?${utmQueryString}`,
    "android_store": `https://play.google.com/store/apps/details?id=com.chemist2u.app&${utmQueryString}`,
    "web": "https://app.chemist2u.com.au",
  }[forwarded_to];

  if (Object.keys(utmData).length > 0) {
    $amplitude.track("APP_DOWNLOAD_REDIRECT", { ...utmData, forwarded_to, forward_link });
  }

  setTimeout(() => {
    window.location.href = forward_link;
  }, 200);

  return false;
};
