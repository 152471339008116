import { Component, input, model } from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  standalone: true,
})
export class RadioComponent {
  public canToggle = input<boolean>(true);
  public disabled = input(false);
  public checked = model<boolean>(false);

  toggle() {
    if (!this.canToggle() || this.disabled()) return;
    this.checked.set(!this.checked());
  }

}
