@if (canDisplay()) {
  <div class="row" [class.total]="isTotal()"  [class.subtotal]="isSubTotal()">
    <div class="label" (click)="showPopover($event)">
      {{ label() }}
      @if (message()) { 
        <ion-icon name="information-circle-outline" />
      }
    </div>
    @if (isReady()) {
      <div class="value" [class.discount]="isDiscount()">{{ ((isDiscount() ? -1 : 1) * value()) | currency }}</div>
    } @else {
      <div class="value" [class.discount]="isDiscount()">Price pending</div>
    }
  </div>
}
