import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { environment } from 'src/environments/environment';
import { ErrorService } from './error.service';
import { EventsService } from './events.service';
import C2U from '@chemist2u/types-client';
import { CustomerScript, User } from '@chemist2u/types-client/C2U/ParseObjects';
import { StateService } from './state.service';
import { ScriptLoaderService } from './script-loader.service';
import { AmplitudeService } from './amplitude.service';
import { HubspotService } from './hubspot.service';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class SetupService {
  private modalController = inject(ModalController);
  private $error = inject(ErrorService);
  private $amplitude = inject(AmplitudeService);
  private $hubspot = inject(HubspotService);
  private $scriptLoader = inject(ScriptLoaderService);

  private $state = inject(StateService);
  private $events = inject(EventsService);

  async dismissAllModals(): Promise<void> {
    const topModal = await this.modalController.getTop();
    if (topModal) {
      await this.modalController.dismiss();
    }
  }

  async loadHubspot() {
    try {
      const hubspotResult = await this.$scriptLoader.load("hubspot");
      console.log("[SetupService]", "loadScripts", "hubspot", hubspotResult[0]);
      this.$amplitude.track('SETUP_HUBSPOT_SUCCESS');
      await this.$hubspot.show();
    } catch (error) {
      console.error("[SetupService]", "loadScripts", "hubspot", error);
      this.$amplitude.track('SETUP_HUBSPOT_FAIL');
    }
  }

  async loadSquare() {
    try {
      const squareResult = await this.$scriptLoader.load("square");
      console.log("[SetupService]", "loadScripts", "square", squareResult[0]);
      this.$amplitude.track('SETUP_SQUARE_SUCCESS');
    } catch (error) {
      console.error("[SetupService]", "loadScripts", "square", error);
      this.$amplitude.track('SETUP_SQUARE_FAIL');
    }
  }

  private setupAnonymousMode(dismissModals: boolean = false): void {
    console.warn("[SetupService]", "Setting Up AnonMode");
    this.$amplitude.track('SETUP_ANONYMOUS_MODE');
    this.$state.killLiveQuery();
    if(dismissModals) {
      this.dismissAllModals();
    }
   
    this.$state.clearState();
    this.$state.initialiseSetupData();
  }

  private setupAuthMode(dismissModals: boolean = false): void {
    console.warn("[SetupService]", "Setting Up AuthMode");
    this.$amplitude.track('SETUP_AUTH_MODE');
    if(dismissModals) {
      this.dismissAllModals();
    }
    this.$state.initialiseAuthSetupData();
    this.$state.initialiseLiveQuery();
  }

  setupParse(): void {
    C2U.initParse({
      SERVER_URL: environment.serverUrl,
      LIVEQUERY_SERVER_URL: environment.liveQueryServerURL,
      APP_ID: environment.appId,
    });
  }

  setupScraperConfig(): void {
    CustomerScript.configScraper(environment.lambdaErxUrl, environment.lambdaErxKey);

  }

  async dismissAnyModals(): Promise<void> {
    const topModal = await this.modalController.getTop();
    if (topModal) {
      await this.modalController.dismiss();
    }
  }

  private async checkAuth(dismissModal: boolean = true): Promise<void> {
    const currentUser = User.current();
    if (!currentUser) {
      this.setupAnonymousMode(dismissModal);
    } else {
      try {
        await currentUser.fetch();
        this.setupAuthMode(dismissModal);
      } catch (error) {
        this.$error.handleParseError(error as Parse.Error);
      }
    }
  }

  private async reactiveCheckAuth(): Promise<void> {
    if(Capacitor.isNativePlatform()) {
      App.addListener("appStateChange", async state => {
        if (state.isActive) {
          // TODO - this loader is very distracting
          //this.$events.triggerAuthChangeStart.next();
          this.checkAuth(false);
        } else {
          this.$state.killLiveQuery();
        }
      });
    }
  }

  public init() {
    this.setupParse();
    this.setupScraperConfig();
    this.checkAuth();
    this.reactiveCheckAuth();
    this.loadSquare();
    if (environment.production) {
      this.loadHubspot();
      const userId = this.$state.bUser.getValue()?.id || undefined;
      this.$amplitude.setUserId(userId);
      this.$amplitude.init();
    }

    this.$events.userLoggedIn.subscribe((user) => {
      this.setupAuthMode();
    });

    this.$events.userLoggedOut.subscribe(() => {
      this.setupAnonymousMode();
    });

    this.$state.bUser.subscribe(user => {
      const id = user?.id || undefined;
      this.$amplitude.setUserId(id);
    });
  }
}