import jsQR from 'jsqr';
import { Injectable } from '@angular/core';
import { Parse } from '@chemist2u/types-client/C2U/local-parse';
import { ICustomerMedicalProfile, TOrderPrescription, TOrderPrescriptionScriptItem } from '@chemist2u/types-client/C2U/Interfaces';
import { LatLngLiteral } from '@googlemaps/google-maps-services-js';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';

export type TCombinedPrescriptionProfilesParam = TOrderPrescription[] | Omit<TOrderPrescription, "amount">[];
export type TCombinedPrescriptionProfilesResult = {
  profile: Parse.Object.ToJSON<ICustomerMedicalProfile> & Parse.JSONBaseAttributes,
  items: TOrderPrescriptionScriptItem[]
}[];

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public combinePrescriptionProfiles(prescriptions: TCombinedPrescriptionProfilesParam): TCombinedPrescriptionProfilesResult {
    if (prescriptions.length === 0) {
      return [];
    }

    const profileMap = new Map();

    for (const prescription of prescriptions) {
      const profile = prescription.prescriptionProfile;
      if (profile && !profileMap.has(profile.objectId)) {
        profileMap.set(profile.objectId, {
          profile,
          items: []
        });
      }
    }

    for (const prescription of prescriptions) {
      const items = prescription.items;
      const profileId = prescription.prescriptionProfile?.objectId;
      if (profileId) {
        profileMap.get(profileId).items.push(...items);
      }
    }

    for (const key of profileMap.keys()) {
      const profile = profileMap.get(key)!;
      if (profile.items.length == 0) {
        profileMap.delete(key);
      }
    }

    return Array.from(profileMap.values());
  }

  public calculateDistanceHaversine(
    point1: LatLngLiteral,
    point2: LatLngLiteral,
    unit: 'km' | 'miles' | 'meters' = 'km'
  ): number {
    const toRadians = (degrees: number): number => degrees * (Math.PI / 180);

    const R = {
      km: 6371,          // Earth's radius in kilometers
      miles: 3958.8,     // Earth's radius in miles
      meters: 6371000,   // Earth's radius in meters
    };

    const lat1 = toRadians(point1.lat);
    const lat2 = toRadians(point2.lat);
    const deltaLat = toRadians(point2.lat - point1.lat);
    const deltaLon = toRadians(point2.lng - point1.lng);

    const a =
      Math.sin(deltaLat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) ** 2;

    const c = 2 * Math.asin(Math.sqrt(a));

    const distance = R[unit] * c;

    return distance;
  }

  private extractQrFromImage(image: Photo): Promise<string> {
    return new Promise((resolve, reject) => {
      const imageElement = new Image();
      imageElement.crossOrigin = "anonymous";
      imageElement.src = `data:image/jpeg;base64,${image.base64String}`;
  
      imageElement.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
          return reject("Failed to get canvas context");
        }
  
        // Resize image if too large
        const maxSize = 500;
        const scaleFactor = Math.min(maxSize / imageElement.width, maxSize / imageElement.height, 1);
        canvas.width = imageElement.width * scaleFactor;
        canvas.height = imageElement.height * scaleFactor;
  
        // Apply image filters to enhance QR detection
        context.filter = "grayscale(100%) contrast(200%)";
        context.drawImage(imageElement, 0, 0, canvas.width, canvas.height);
  
        // Extract image data
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);
  
        if (code) {
          resolve(code.data);
        } else {
          reject("No QR code found in image!");
        }
      };
  
      imageElement.onerror = () => reject("Failed to load image");
    });
  }
  
  public async uploadQrImage(): Promise<string> {
    let image: Photo | undefined;
    try {
      image = await Camera.getPhoto({
        quality: 80,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        saveToGallery: true,
        source: CameraSource.Photos
      });
    } catch (error) {
      throw new Error("No image selected!");
    }
  
    try {
      return await this.extractQrFromImage(image);
    } catch (error) {
      throw new Error("No QR code found in image!");
    }
  }

}