import { LegacyAmplitudeEvents as Legacy } from "./amplitude_legacy_events";

export interface AmplitudeEventProps {
	[key: string]: string | number | string[] | number[]
}

export type TGenericEventProps = { origin: string, content: string, UI: string };

export type AmplitudeEventPropsMap = {
	// Add Script
	ADD_SCRIPT_STEP_CHANGE: { step: "select-medical-profile" | "create-medical-profile" | "provide-erx-token" | "review" | "checkout" };
	ADD_SCRIPT_QR_TOKEN_LIBRARY_FAIL: { reason: string };

	// Item Search
	ITEM_SEARCH_HAS_RESULTS: { searchTerm: string };
	ITEM_SEARCH_HAS_NO_RESULTS: { searchTerm: string };
    
	// Cart
	CART_FULFILLMENT_METHOD_CHANGE: { methodName: string };
	CART_STEP_CHANGE: { step: "cart" | "shipping" | "payment" };
	CART_CHECKOUT_FAIL: { error: string };
	
	// Permissions
	PERMISSION_TOGGLE_START: { permission: string, to_state: 'enabled' | 'disabled' };
	PERMISSION_TOGGLE_SUCCESS: { permission: string, to_state: 'enabled' | 'disabled' };
	PERMISSION_TOGGLE_FAIL: { permission: string, to_state: 'enabled' | 'disabled' };
	
	// Edit Profile Field
	EDIT_PROFILE_FIELD_SUCCESS: { field: string };
	EDIT_PROFILE_FIELD_FAIL: { field: string };
	EDIT_PROFILE_FIELD_START: { field: string };
	EDIT_PROFILE_FIELD_CLOSE: { field: string };

	// Cart Item
	CART_ITEM_REMOVE_SUCCESS: { id: string, name: string };
	CART_ITEM_REMOVE_FAIL: { id: string, name: string };
	CART_ITEM_EDIT_SUCCESS: { id: string, name: string, qty: number };
	CART_ITEM_EDIT_QTY_INC: { id: string, name: string, qty: number };
	CART_ITEM_EDIT_QTY_DEC: { id: string, name: string, qty: number };

	// Order
	ORDER_PAY_START: { orderId: string };
	ORDER_PAY_SUCCESS: { orderId: string };
	ORDER_PAY_FAIL: { orderId: string, message: string };

	// Orders
	ORDERS_SWITCH_SEGMENT: { segment: string };
	
	// Address
	ADDRESS_SEARCH: { searchTerm: string };
	ADDRESS_SEARCH_HAS_RESULTS: { searchTerm: string };
	ADDRESS_SEARCH_HAS_NO_RESULTS: { searchTerm: string };
	ADDRESS_SELECTED_GEO: { postalCode: string };
	ADDRESS_SELECTED_RECENT: { postalCode: string };
	ADDRESS_SELECTED_SEARCH: { postalCode: string };
	ADDRESS_DELIVERY_NOTES: { deliveryNotes: string };
	ADDRESS_FULFILLMENT_METHOD: { methodName: string };
	ADDRESS_SAVE: { postalCode: string };
	
	APP_DOWNLOAD_REDIRECT: { forwarded_to: string, forward_link: string, utm_source?: string, utm_medium?: string, utm_campaign?: string, utm_term?: string, utm_content?: string };

	// Navigation
	GENERIC_NAVIGATE: { to_page: string };
	NAVIGATE_MEDICINES: { categoryId?: string, subcategoryId?: string };
	NAVIGATE_CATEGORY: { categoryId: string };
	NAVIGATE_SUBCATEGORY: { subcategoryId: string };
	NAVIGATE_ORDER: { id: string };
	NAVIGATE_PAST_ORDER: { id: string };
	EXIT_ORDER: { id: string };

	// Modals
	MODAL_SHOW: { modal: string };
	MODAL_DISMISS: { modal: string };
	MODAL_SHOW_ITEM: { id: string, name: string };

	// Hubspot
	HUBSPOT_OPEN: { conversationId: string };
	HUBSPOT_CLOSE: { conversationId: string };

	//  ----------------------------------------------------------------------------------------------------
	// Added For MyScripts
	//  ----------------------------------------------------------------------------------------------------
	PROMO_PROMPT_VIEWED: TGenericEventProps;
	PROMO_PROMPT_CLOSED: TGenericEventProps;
	LEARN_MORE_CLICKED: TGenericEventProps;
	SCRIPT_DETAILS_CLICKED: TGenericEventProps;
	SHARE_SCRIPT_CLICKED: TGenericEventProps;
	ORDER_DELIVERY_CLICKED: TGenericEventProps;
	MANAGE_SCRIPTS_CLICKED: TGenericEventProps;
	SHOW_ORIGINAL_CLICKED: TGenericEventProps;
	REMOVE_SCRIPT_CLICKED: TGenericEventProps;
	SCRIPT_UPLOAD_VIEWED: TGenericEventProps;
	SCRIPT_SAVED_VIEWED: TGenericEventProps;
	SCRIPT_DETAILS_VIEWED: TGenericEventProps;
	MY_SCRIPTS_VIEWED: TGenericEventProps;
	MY_SCRIPTS_EMPTY_VIEWED: TGenericEventProps;
	DELETE_SCRIPT_VIEWED: TGenericEventProps;
	REMOVE_SCRIPT_CONFIRMED: TGenericEventProps;
	REMOVE_SCRIPT_CANCELLED: TGenericEventProps;

	ADD_SCRIPT_CTA: TGenericEventProps;
	ADD_SCRIPT_TOKEN_PASTE: TGenericEventProps;
	ADD_SCRIPT_TOKEN_TYPE: TGenericEventProps;
};

export type AmplitudeEventKey = keyof typeof AmplitudeEvents;

export const AmplitudeEvents = {
	// Pharmacy Address
	PHARMACY_ADDRESS_COPY: "User copied address for pharmacy location",
	PHARMACY_ADDRESS_OPEN_MAP: "User opened map for pharmacy location",
	
	// App Update
	APP_UPDATE_LIVE: "Live app web update initiated",
	APP_UPDATE_FROM_STORE: "User redirected to store for app update",
	APP_UPDATE_FAIL: "App update failed",
	APP_UPDATE_DOWNLOAD_START: "App update download started",
	APP_UPDATE_DOWNLOAD_PROGRESS: "App update download in progress",
	APP_UPDATE_DOWNLOAD_COMPLETE: "App update download completed",
	APP_UPDATE_INSTALL_START: "App update installation started",
	APP_UPDATE_INSTALL_COMPLETE: "App update installation completed",

	// Card
	CARD_CREATE_SUCCESS: Legacy.ADD_PAYMENT_METHOD_SUCCESS,
	CARD_TOKENIZATION_FAIL: Legacy.ADD_PAYMENT_METHOD_FAIL_1,
	CARD_CREATE_FAIL: Legacy.ADD_PAYMENT_METHOD_FAIL_2,
	CARD_SELECTION_CHANGE: Legacy.UPDATE_PAYMENT_METHOD,
	CARD_REMOVE_SUCCESS: Legacy.DELETE_PAYMENT_METHOD,

	// Add Script
	ADD_SCRIPT_CTA: Legacy.ADD_SCRIPT_CTA,
	ADD_SCRIPT_EXIT: Legacy.UPLOAD_SCRIPT_CANCEL,
	ADD_SCRIPT_STEP_CHANGE: "User changed 'Add Script' step",
	// ADD_SCRIPT_QR_TOKEN_SCAN_START: "User started scanning a QR code image from camera",
	// ADD_SCRIPT_QR_TOKEN_SCAN_SUCCESS: Legacy.USER_QR_CAMERA,
	ADD_SCRIPT_QR_TOKEN_LIBRARY_START: Legacy.UPLOAD_IMAGE_START,
	// ADD_SCRIPT_QR_TOKEN_LIBRARY_SELECTED: Legacy.UPLOAD_IMAGE_SELECT,
	// ADD_SCRIPT_QR_TOKEN_LIBRARY_UPLOAD_SUCCESS: Legacy.UPLOAD_IMAGE_SUCCESS,
	// ADD_SCRIPT_QR_TOKEN_LIBRARY_UPLOAD_FAIL: Legacy.UPLOAD_IMAGE_FAIL,
	ADD_SCRIPT_QR_TOKEN_LIBRARY_SUCCESS: Legacy.USER_QR_LIBRARY,
	ADD_SCRIPT_QR_TOKEN_LIBRARY_FAIL: "QR code select/parse failed",
    ADD_SCRIPT_TOKEN_PASTE: "User pasted an eRx token",
	ADD_SCRIPT_TOKEN_TYPE: "User entered eRx token manually",
	ADD_SCRIPT_TOKEN_VALIDATION_SUCCESS: Legacy.USER_UPLOADED_ERX_TOKEN,
	ADD_SCRIPT_TOKEN_VALIDATION_FAIL: Legacy.USER_INVALID_ERX_TOKEN,
	ADD_SCRIPT_DELETE: "User deleted script",
	ADD_SCRIPT_TO_CART_START: Legacy.ADD_SCRIPT_SUBMIT,
	ADD_SCRIPT_TO_CART_SUCCESS: Legacy.ADD_SCRIPT_SUCCESS,
	ADD_SCRIPT_ADD_ANOTHER: Legacy.ADD_ANOTHER_SCRIPT,
	ADD_SCRIPT_FINISH: "User clicked 'Continue to checkout'",

	// ATL
	ATL_TOGGLED: "User toggled ATL selection",
	ATL_SAVE_SUCCESS: "User successfully saved ATL settings",
	ATL_SAVE_FAIL: "Error saving ATL settings",

	// Item Search
	ITEM_SEARCH_CHANGE: "User typed into search input",
	ITEM_SEARCH_CLEARED: "User cleared search input",
	ITEM_SEARCH_FOCUS_IN: "User focused on search input",
	ITEM_SEARCH_FOCUS_OUT: "User unfocused from search input",
	ITEM_SEARCH_TERM_SELECTED: "User selected a search term",
	ITEM_SEARCH_HAS_RESULTS: Legacy.ITEM_SEARCH,
	ITEM_SEARCH_HAS_NO_RESULTS: Legacy.ITEM_SEARCH_FAIL,
	ITEM_SEARCH_ADD_SCRIPT: "User clicked upload script from OTC search",

	// Cart
    CART_FULFILLMENT_METHOD_CHANGE: "User changed fulfilment method",
	CART_STEP_CHANGE: "User changed 'Cart' step",
	CART_SCRIPT_REMOVE_SUCCESS: Legacy.CART_REMOVED_SCRIPT,
	CART_SCRIPT_REMOVE_FAIL: "Error removing script from cart",
	CART_PROMOTION_CLEAR_SUCCESS: Legacy.CHECKOUT_PROMO_REMOVED_SUCCESS,
	CART_PROMOTION_CLEAR_FAIL: Legacy.CHECKOUT_PROMO_REMOVED_FAIL,
	CART_PROMOTION_SELECT_OPEN: Legacy.PROMO_OPEN,
	CART_PROMOTION_SELECT_CLOSE: Legacy.PROMO_CLOSE,
	CART_PROMOTION_SELECT_SUCCESS: Legacy.PROMO_SELECT_SUCCESS,
	CART_PROMOTION_SELECT_FAIL: Legacy.PROMO_SELECT_FAIL,
	CART_CARD_SELECTION_CHANGE: "User changed card selected",
	CART_EMPTIED: Legacy.CART_EMPTIED,
	CART_ADD_SCRIPT: Legacy.CHECKOUT_ADD_SCRIPT,
	CART_ADD_OTC_ITEM: Legacy.CHECKOUT_ADD_ITEM,
	CART_CHECKOUT_SUCCESS: Legacy.CHECKOUT_SUBMIT_SUCCESS,
	CART_CHECKOUT_FAIL: Legacy.CHECKOUT_ERROR,
	CART_ORDER_CREATE_SUCCESS: "Order was successfully created",
	CART_ORDER_CREATE_FAIL: "Error occurred while creating order",
	CART_WEIGHT_LOSS_ORDER_CREATE_SUCCESS: "Weight loss order was successfully created",
	CART_WEIGHT_LOSS_ORDER_CREATE_FAIL: "Error occurred while creating weight loss order",

	CART_INITED_SET_ADDRESS: Legacy.CHECKOUT_EDIT_ADDRESS,

	// Click And Collect
	CLICK_AND_COLLECT_ARRIVED_CLICK: "User clicked 'I have arrived' at click & collect",
	CLICK_AND_COLLECT_NOT_ARRIVED_CLICK: "User clicked 'Not yet arrived' at click & collect",
	CLICK_AND_COLLECT_ORDER_PICKUP: "User confirmed order picked up at click & collect",
	CLICK_AND_COLLECT_CONFIRM_CLICK: "User clicked 'Confirm' at click & collect",

	// Promotion
	PROMOTION_NOT_FOUND: Legacy.PROMO_ADD_FAIL,
	PROMOTION_ADD_SUCCESS: Legacy.PROMO_ADD_SUCCESS,
	PROMOTION_ADD_FAIL: "Error adding promotion",
	PROMOTION_REMOVE_SUCCESS: "User successfully removed promotion",

	// Permissions
	PERMISSION_TOGGLE_START: "User toggling device permission",
	PERMISSION_TOGGLE_SUCCESS: "User device permission toggled successfully",
	PERMISSION_TOGGLE_FAIL: "Error toggling device permission",

	// Medical Profile
	MEDICAL_PROFILE_SELECTED: Legacy.SELECT_MEDICAL_PROFILE,
	MEDICAL_PROFILE_ADD_START: Legacy.ADD_MEDICAL_PROFILE_START,
	MEDICAL_PROFILE_ADD_SUCCESS: Legacy.MEDICAL_PROFILE_SUBMITTED,
	MEDICAL_PROFILE_ADD_FAIL: Legacy.REVIEW_MEDICAL_PROFILE_FAIL,
	MEDICAL_PROFILE_EDIT_START: Legacy.UPDATE_MEDICAL_PROFILE_START,
	MEDICAL_PROFILE_EDIT_SUCCESS: Legacy.MEDICAL_PROFILE_UPDATED,
	MEDICAL_PROFILE_EDIT_FAIL: Legacy.REVIEW_MEDICAL_PROFILE_FAIL,

	// Edit Profile Field
	EDIT_PROFILE_FIELD_START: "User started editing personal info field",
	EDIT_PROFILE_FIELD_CLOSE: "User stopped editing personal info field",
	EDIT_PROFILE_FIELD_SUCCESS: Legacy.UPDATE_PERSONAL_INFO,
	EDIT_PROFILE_FIELD_FAIL: "Error while editing personal info field",

	// Item
	ITEM_LOAD_SUCCESS: "Item loaded successfully",
	ITEM_LOAD_FAIL: "Error loading item",
	ITEM_ADD_TO_CART_SUCCESS: Legacy.ADD_TO_CART,
	ITEM_ADD_TO_CART_FAIL: "Error adding item to cart",

	// Cart Item
	CART_ITEM_EDIT_OPENED: Legacy.CART_OPEN_ITEM,
	CART_ITEM_EDIT_CLOSED: Legacy.CART_CLOSE_ITEM,
	CART_ITEM_REMOVE_SUCCESS: Legacy.CART_REMOVED_ITEM,
	CART_ITEM_REMOVE_FAIL: Legacy.CART_REMOVED_ITEM_FAIL,
	CART_ITEM_EDIT_SUCCESS: Legacy.CART_UPDATED,
	CART_ITEM_EDIT_FAIL: Legacy.CART_UPDATED_FAIL,
	CART_ITEM_EDIT_QTY_INC: Legacy.CART_INCREASED_QTY,
	CART_ITEM_EDIT_QTY_DEC: Legacy.CART_REDUCED_QTY,

	// OTP
	OTP_SEND_SUCCESS: Legacy.AUTHY_CODE_SENT,
	OTP_VERIFY_SUCCESS: Legacy.AUTHY_CODE_SUCCESS,
	OTP_VERIFY_FAIL: Legacy.AUTHY_CODE_FAIL,

	// Order
	ORDER_PAY_START: "User started pay process for existing order",
	ORDER_PAY_SUCCESS: "User successfully paid for existing order",
	ORDER_PAY_FAIL: "Error paying for existing order",

	// Orders
	ORDERS_SWITCH_SEGMENT: "User switched segment in orders page",

	// Reset Password
	RESET_PASSWORD_SEND_SUCCESS: "User reset password request successful",
	RESET_PASSWORD_SEND_FAIL: "Error sending User's reset password request",

	// Address
	ADDRESS_SEARCH: "User made an address search",
	ADDRESS_SEARCH_HAS_RESULTS: Legacy.ADDRESS_SEARCHING,
	ADDRESS_SEARCH_HAS_NO_RESULTS: Legacy.ADDRESS_SEARCH_FAIL,
	ADDRESS_SELECTED_GEO: Legacy.GEO_ADDRESS_SELECT,
	ADDRESS_SELECTED_RECENT: Legacy.RECENT_ADDRESS_SELECT,
	ADDRESS_SELECTED_SEARCH: Legacy.ADDRESS_SEARCH_SUCCESS,
	ADDRESS_DELIVERY_NOTES: "User changed their delivery notes",
	ADDRESS_FULFILLMENT_METHOD: "User changed their fulfillment method",
	ADDRESS_SAVE: "User updated their address",
	ADDRESS_HAS_PHARMACY: Legacy.PHARMACY_SEARCH_SUCCESS,
	ADDRESS_HAS_NO_PHARMACY: Legacy.PHARMACY_SEARCH_FAIL,

	// Open External
	OPEN_EXTERNAL_TERMS_OF_SERVICE: Legacy.VIEW_TERMS_OF_SERVICE,
	OPEN_EXTERNAL_PRIVACY_POLICY: Legacy.VIEW_PRIVACY_POLICY,
	OPEN_EXTERNAL_HELP: Legacy.TO_HELP,

	// Verify Password
	VERIFY_PASSWORD_START: "User prompted for password verification",
	VERIFY_PASSWORD_SUCCESS: "User successfully verified password",
	VERIFY_PASSWORD_FAIL: "User password verification failed",

	//  ----------------------------------------------------------------------------------------------------
	// Used in Services
	//  ----------------------------------------------------------------------------------------------------

	// Auth
	AUTH_LOGIN_START: "Login initiated",
	AUTH_LOGIN_BIOMETRICS: "Login with biometrics initiated",
	AUTH_LOGIN_PHONE_ENTERED: Legacy.SIGNIN_PHONE_NUMBER,
	AUTH_LOGIN_USER_FOUND: Legacy.SIGNIN_USER_FOUND,
	AUTH_LOGIN_USER_NOT_FOUND: Legacy.SIGNIN_USER_NOT_FOUND,
	AUTH_LOGIN_SUCCESS: Legacy.LOGIN_SUCCESS,
	AUTH_LOGIN_FAIL: Legacy.LOGIN_FAIL,
	AUTH_LOGOUT: Legacy.LOGOUT,
	AUTH_SIGNUP_SUCCESS: Legacy.SIGNUP_SUBMIT_SUCCESS,
	AUTH_SIGNUP_FAIL: Legacy.SIGNUP_SUBMIT_FAIL,
	AUTH_SESSION_EXPIRED: "User session expired",

	// Biometrics
	BIOMETRICS_SETUP_INITIATED: "User initiated biometric setup",
	BIOMETRICS_SETUP_SUCCESS: "User successfully set up biometrics",
	BIOMETRICS_SETUP_FAIL: "Error setting up biometrics",
	BIOMETRICS_VERIFY_SUCCESS: "User successfully verified biometrics",
	BIOMETRICS_VERIFY_FAIL: "Error verifying biometrics",
	BIOMETRICS_REMOVE_SUCCESS: "User successfully removed biometrics",
	BIOMETRICS_REMOVE_FAIL: "Error removing biometrics",

	// Push Notifications
	PUSH_NOTIFICATION_REGISTRATION_SUCCESS: "User device successfully registered to push notifications",
	PUSH_NOTIFICATION_REGISTRATION_FAIL: "Error registering user device to push notifications",
	PUSH_NOTIFICATION_UNREGISTERED: "User device unregistered from push notifications",
	PUSH_NOTIFICATION_RECEIVED: "User device received push notification",
	PUSH_NOTIFICATION_ACTION_PERFORMED: "User acted on push notification",

	// Setup
	SETUP_HUBSPOT_SUCCESS: "Hubspot script successfully loaded",
	SETUP_HUBSPOT_FAIL: "Error loading Hubspot script",
	SETUP_SQUARE_SUCCESS: "Square script successfully loaded",
	SETUP_SQUARE_FAIL: "Error loading Square script",
	SETUP_AUTH_MODE: "User set up in auth mode",
	SETUP_ANONYMOUS_MODE: "User set up in anonymous mode",

	//  ----------------------------------------------------------------------------------------------------
	// General
	//  ----------------------------------------------------------------------------------------------------

	APP_DOWNLOAD_REDIRECT: "App Download Redirect",

	// Navigation
    GENERIC_NAVIGATE: "User navigated to page",
	GENERIC_BACK: Legacy.GENERIC_BACK,
	NAVIGATE_HOME: Legacy.TO_HOME,
	NAVIGATE_BROWSE: Legacy.TO_BROWSE,
	NAVIGATE_MEDICINES: "User navigated to medicine category/subcategory",
	NAVIGATE_CATEGORY: Legacy.PRODUCTS_TO_CATEGORY,
	NAVIGATE_SUB_CATEGORY: Legacy.PRODUCTS_TO_SUBCATEGORY,
	NAVIGATE_ORDERS: Legacy.TO_ORDERS,
	NAVIGATE_ACCOUNT: Legacy.TO_ACCOUNT,
	NAVIGATE_ORDER: Legacy.VIEW_ORDER,
	NAVIGATE_PAST_ORDER: Legacy.VIEW_PAST_ORDER,
	NAVIGATE_SETTINGS: Legacy.TO_SETTINGS,
	NAVIGATE_MEDICAL_PROFILES: Legacy.TO_MEDICAL_PROFILE,
	NAVIGATE_PROFILE: Legacy.TO_PERSONAL_INFO,
	NAVIGATE_WALLET: Legacy.TO_WALLET,
	NAVIGATE_CARDS: Legacy.TO_PAYMENT_METHODS,
	NAVIGATE_PROMOTIONS: Legacy.TO_COUPONS,
	EXIT_ORDER: Legacy.CLOSE_ORDER_PAGE,
	EXIT_MEDICINES: Legacy.BACK_TO_CATEGORIES,
	
	// Modals
	MODAL_SHOW_LOGIN: Legacy.TO_LOGIN,
	MODAL_SHOW_APP_UPDATE: "App Update modal shown",
    MODAL_SHOW: "Modal shown",
    MODAL_DISMISS: "Modal dismissed",

	MODAL_SHOW_RESET_PASSWORD: Legacy.TO_FORGOT_PASSWORD,
	MODAL_DISMISS_RESET_PASSWORD: "User closed forgot password",

	MODAL_SHOW_ITEM: Legacy.VIEW_ITEM,
	MODAL_DISMISS_ITEM: "Dismissed item modal",

	MODAL_SHOW_CART: Legacy.VIEW_CART,
	MODAL_DISMISS_CART: Legacy.CLOSE_CART,

	MODAL_SHOW_SET_ADDRESS: Legacy.TO_SET_ADDRESSS,
	MODAL_SHOW_SET_ADDRESS_METHOD: "User opened fulfillment method",
	MODAL_SHOW_SET_ADDRESS_NOTES: Legacy.OPEN_DELIVERY_INSTRUCTIONS,
	MODAL_DISMISS_SET_ADDRESS: Legacy.ADDRESS_SEARCH_CLOSE,

	MODAL_SHOW_ADD_CARD: Legacy.OPEN_PAYMENT_FORM,
	MODAL_DISMISS_ADD_CARD: Legacy.CLOSE_PAYMENT_FORM,

	MODAL_SHOW_WALLET: Legacy.OPEN_WALLET,
	MODAL_DISMISS_WALLET: Legacy.CLOSE_WALLET,

	MODAL_SHOW_RESCHEDULE: Legacy.SELECT_SCHEDULE_DELIVERY,
	MODAL_DISMISS_RESCHEDULE: "User closed schedule delivery",

	// By Role

	ANON_SIGNUP: Legacy.ANONYMOUS_OPEN_SIGNUP,
	ANON_ADD_SCRIPT: Legacy.ANONYMOUS_USER_CLICKED_ON_UPLOAD_SCRIPT_BOX,
	USER_ADD_SCRIPT: Legacy.USER_CLICKED_ON_UPLOAD_SCRIPT_BOX,

	// Hubspot
	HUBSPOT_OPEN: Legacy.CHAT_STARTED,
	HUBSPOT_CLOSE: Legacy.CHAT_CLOSED,

	//  ----------------------------------------------------------------------------------------------------
	// Added For MyScripts
	//  ----------------------------------------------------------------------------------------------------

	PROMO_PROMPT_VIEWED: "promo_prompt_viewed",
	PROMO_PROMPT_CLOSED: "promo_prompt_closed",

	LEARN_MORE_CLICKED: "learn_more_clicked",
	SCRIPT_DETAILS_CLICKED: "script_details_clicked",
	SHARE_SCRIPT_CLICKED: "share_script_clicked",
	ORDER_DELIVERY_CLICKED: "order_delivery_clicked",
	MANAGE_SCRIPTS_CLICKED: "manage_scripts_clicked",
	SHOW_ORIGINAL_CLICKED: "show_original_clicked",
	REMOVE_SCRIPT_CLICKED: "remove_script_clicked",

	SCRIPT_UPLOAD_VIEWED: "script_upload_viewed",
	SCRIPT_SAVED_VIEWED: "script_saved_viewed",
	SCRIPT_DETAILS_VIEWED: "script_details_viewed",

	MY_SCRIPTS_VIEWED: "my_scripts_viewed",
	MY_SCRIPTS_EMPTY_VIEWED: "my_scripts_empty_viewed",
	DELETE_SCRIPT_VIEWED: "delete_script_viewed",
	REMOVE_SCRIPT_CONFIRMED: "remove_script_confirmed",
	REMOVE_SCRIPT_CANCELLED: "remove_script_cancelled",
};