import { Parse } from '@chemist2u/types-client/C2U/local-parse';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UiService } from '../services/ui.service';

export const authGuard: CanActivateFn = (route, state) => {
    const $ui = inject(UiService);
    const router = inject(Router);
    const currentUser = Parse.User.current();
    if (!currentUser) {
      router.navigate(['/tabs/home']).then(() => {
        $ui.showLogin.next();
      });
      return false;
    }
    return true;
};