<ion-header>
  <app-header background="faded">
    <app-simple-button (click)="previousStep()" color="main" size="large" slot="start" icon="chevron-back-outline" />
    <app-title slot="main" size="small" title="Order address" />
    <app-simple-button (click)="dismiss()" size="large" color="main" slot="end" label="Close" />
  </app-header>
</ion-header>

<ion-content>
  <app-steps-container [step]="currentStep()">


    <!-- STEP 1 - Address Search -->
    <div class="step predictions">
      <app-input [showClearButtonOption]="true" label="Address" [debounceTime]="1000" [value]="addressSearchValue" (valueChange)="searchForAddress($event)" />
      <app-address-list (addressSelected)="searchAddressSelected($event)" [predictions]="predictions()"/>
        @if (!predictions().length) {
        <div class="list-wrapper">
          @if (currentLocation()) {
            <app-address-list-view class="current" title="Current Location">
              <app-address-row
                (click)="selectGeoAddress()"
                [addressComponents]="currentLocation()?.address_components"
                [includeUnitNumber]="false"
                icon="navigate"
              />
            </app-address-list-view>
          }
          @if(customerAddresses() && customerAddresses().length) {
            <app-address-list-view class="addresses" title="Recent Addresses">
              @for (address of customerAddresses(); track $index) {
                <app-address-row
                  (click)="selectSavedAddress(address)"
                  [addressComponents]="address.address.address_components"
                  icon="location"
                />
              }
            </app-address-list-view>
          }
          
        </div>
       }
    </div>


    <!-- STEP 2 - Delivery Notes & Apt Check-->
    <div class="step delivery">
      @if(finalAddress) {
        <app-address-details [canEdit]="true" (editAddressEvent)="previousStep()" [placeDetails]="finalAddress" [deliveryNoteEditMode]="true" />
      }
      <app-input label="Unit (optional)" [(value)]="unitNumber" (valueChange)="unitNumberChanged($event)" />
      <app-input label="Delivery instructions (optional)" [(value)]="deliveryNotes" (valueChange)="assignDeliveryNotes($event)" />
    </div>


    <!-- STEP 3 - Serviceability -->
    <div class="step serviceability">
      @if(finalAddress) {
        <app-address-details [canEdit]="true" (editAddressEvent)="goToFirstStep()" (editNotesEvent)="previousStep()" [placeDetails]="finalAddress" [deliveryNoteEditMode]="false" />
      }
      <app-delivery-method [pharmacyAddress]="pharmacyAddress()" [customerAddress]="finalAddress" (selectMethodEvent)="methodSelected($event)" [prefFulfillmentMethod]="preferredMethodOnSession()" [orderFulfillmentMethods]="orderFulfillmentMethods()" [selectedDeliveryMethod]="methodToPass()"  />
    </div>


  </app-steps-container>
</ion-content>

<ion-footer>
  <app-footer>
    @if (!isLastStep()) {
      <app-button label="Next" (click)="setDeliveryNotes()" [disabled]="nextButtonDisabled()" size="large"/>
    } @else {
      <app-button label="Save" (click)="setAddress()" size="large" />
    }
  </app-footer>
</ion-footer>