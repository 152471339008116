<div class="content">
  @for (totals of totalsList()!; track $index) {
    @if (totalsList() && totalsList()!.length > 1) {
      <app-title title="Order {{ $index + 1 }}" />
    }
    <div class="totals">
      @for (lineTotal of totals; track lineTotal.key) {
        <app-cart-totals-row [lineItem]="lineTotal" [isSubTotal]="lineTotal.key == 'ltSubTotal'" [isTotal]="lineTotal.key == 'ltTotal'" />
      }
      @if (hasUnpricedItems() && !hasPricedButPendingItems()) {
        <app-info-bubble label="Your cart has an unpriced item. We'll source pricing from our pharmacy partner once you submit your order. You'll receive a text with the updated price and payment instructions." />
      }
      @if (hasPricedButPendingItems()) {
        <app-info-bubble label="Your cart has items are priced but they need to be verified by our pharmacy partner before totals can be calculated. You'll receive a text with the updated price and payment instructions." />
      }
      @if ($index === 1) {
        <app-info-bubble label="Your order contains weight loss medication. A separate order will be placed for weight loss products. The current totals only reflect the items which can be fulfilled from your local pharmacy." />
      }
    </div>
  }
  <app-encrypted-label />
</div>