export const environment = {
  production: true,
  serverUrl: 'https://manage1.chemist2u.com.au/parse',
  liveQueryServerURL: 'wss://manage1.chemist2u.com.au/parse',
  // liveQueryServerURL: 'wss://livequery.chemist2u.com.au/parse', // Comment this out for now
  appUpdatesServer: 'https://app-updates.chemist2u.com.au',
  appUpdatesServerKey: 'TYKyMlyewE0WiZUEs7Mkh6WApN6hE8RIkl71w82KcheuqelKEI6CsQRE5ycS5bhgVMeN0E1d7uLcKwJN1ITy8PXYtoRdsWqRFx8J8FIdGidzmpEELOoGRWKwxqt1tnLr',
  appId: '47civNnjdoUKsukw0xxS',
  sqAppID: 'sq0idp-uk651ep5-We8yTt8xWN1zg',
  sqLocationID:'MNKH3V06R5XGZ',
  appName: 'Chemist2U',
  amplitudeKey: 'b35dccd7802be1e7415a97c13baba7e9',
  sentryDSN: 'https://026551be4631a88c401fbfc1f8b9559b@o1407837.ingest.us.sentry.io/4507999410061312',
  lambdaErxUrl: 'https://jux5s7yuf4myf4otdrv53y2aey0pqmgy.lambda-url.ap-southeast-2.on.aws/erx-scraper?code=',
  lambdaErxKey: 'e4f137c2-41d6-4f8d-a391-33a97ee124d3',
};